import React from "react";
import PropTypes from "prop-types";

import FixedParcelamento from "../FixedParcelamento";
import DefaultParcelamento from "../DefaultParcelamento";

import { getClientData } from "../../../services/auth";

function Solar(props) {
  const { qtdDias, changeParcelamentoSolar, condicoes, cdProjeto } = props;

  let activeCondicao = false;

  if (cdProjeto.toString() === "1") {
    activeCondicao = "default";
    const clientData = getClientData();
    const currentDate = new Date().valueOf();
    if (clientData.cd_logged_project === 1) {
      if (
        currentDate >= 1730689200000 && // 04/11/2024
        currentDate < 1737417600000 // 20/01/2025
      ) {
        activeCondicao = condicoes["30/60/90"];
      }
    }
  } else if (cdProjeto.toString() === "3") {
    activeCondicao = condicoes["90/120/150"];
  }

  return (
    activeCondicao &&
    (activeCondicao === "default" ? (
      <DefaultParcelamento />
    ) : (
      <FixedParcelamento
        qtdDias={qtdDias}
        changeParcelamento={changeParcelamentoSolar}
        condicoes={activeCondicao}
      />
    ))
  );
}
Solar.propTypes = {
  qtdDias: PropTypes.string.isRequired,
  changeParcelamentoSolar: PropTypes.func.isRequired,
  condicoes: PropTypes.shape({
    "30/60/90": PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        cdParcela: PropTypes.number.isRequired,
      })
    ),
    "60/90/120": PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        cdParcela: PropTypes.number.isRequired,
      })
    ),
    "90/120/150": PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        cdParcela: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  cdProjeto: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default Solar;
