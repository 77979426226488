import { darken } from "polished";

import styled from "styled-components";

import metrics from "./metrics";

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    margin-top: -5px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    text-align: right;
  }
`;

export const ProductsTable = styled.table`
  border: 1px solid #ccc;
  padding: 3px;
  width: 100%;
  margin-bottom: ${metrics.baseMargin}px;

  th {
    background: #7eb9cd;
    padding: 5px;
    color: #223f45;
  }
`;

export const ItemLine = styled.tr`
  animation: fadeIn 0.4s linear;

  td {
    padding: 5px;
    border-bottom: 1px solid #ddd;
    background-color: ${(props) => {
      if (props.bloqueio) return "#b9cfb5";
      if (props.lancamento) return "#a55da5";
      if (props.promocao) return "#99ccff";
      return "inherit";
    }};
  }

  :hover {
    td {
      background: ${(props) => {
        if (props.bloqueio) return darken(0.1, "#b9cfb5");
        if (props.lancamento) return darken(0.1, "#a55da5");
        if (props.promocao) return darken(0.1, "#99ccff");
        return "rgba(0, 0, 0, 0.03)";
      }};
    }
  }
`;

export const QtdColumn = styled.td`
  width: 70px;

  input {
    background-color: ${(props) => (props.estoqueBaixo ? "#fcc" : "#fff")};
    border: 1px solid #666;
    font-size: 1.1em;
    padding: 3px;
    text-align: right;
    width: 98%;
  }
`;

export const NoQtdColumn = styled.td`
  width: 70px;
  font-size: 1.5em;
  padding: 3px;
  text-align: center;
`;

export const CodColumn = styled.td`
  text-align: center;
`;

export const VlrColumn = styled.td`
  width: 150px;
  text-align: right;
`;

export const WideVlrColumn = styled(VlrColumn)`
  width: 250px;
`;

export const PriceColumn = styled(VlrColumn)`
  width: 250px;
  position: relative;
`;

export const PriceLabel = styled.span`
  text-align: left;
`;
export const PriceValue = styled.span`
  text-align: right;
`;

export const PriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.5em;
  font-size: 0.8em;
`;

export const AddPriceItem = styled(PriceItem)`
  /* color: #d10000; */
  font-weight: bold;

  & ${PriceValue}::before {
    content: "+ ";
  }
`;

export const SubPriceItem = styled(PriceItem)`
  color: #008000;

  & ${PriceValue}::before {
    content: "- ";
  }
`;

export const PriceSuggestionItem = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.5em;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.6);
`;

export const PriceItemSeparator = styled.hr`
  margin-bottom: 3px;
`;

export const TotalPriceItem = styled(PriceItem)`
  line-height: 1em;
  font-size: 1em;
`;

export const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
`;
