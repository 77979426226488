import React, { Component } from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Loader from "react-loader-spinner";
import ReactPaginate from "react-paginate";

import { Creators as ProductsActions } from "../../store/ducks/products";
import { Creators as OrderActions } from "../../store/ducks/order";
import { Creators as IndustriesActions } from "../../store/ducks/industries";

import { setTitle } from "../../services/browser";
import {
  ButtonsContainer,
  LoaderContainer,
  PaginatorContainer,
} from "../../styles/common";
import { Container } from "./styles";

import BtnPrimary from "../../components/BtnPrimary";
import ProductList from "../../components/ProductList";
import { Box } from "@material-ui/core";
import ColorsLegend from "../../components/ProductList/components/ColorsLegend";

class Products extends Component {
  static propTypes = {
    productRequest: PropTypes.func.isRequired,
    getOrderRequest: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    setSearchIndustry: PropTypes.func.isRequired,
    setSearchCategory: PropTypes.func.isRequired,
    setSearchCategoryGroup: PropTypes.func.isRequired,
    setSearchProductType: PropTypes.func.isRequired,
    industryRequest: PropTypes.func.isRequired,
    sendItemsRequest: PropTypes.func.isRequired,
    setItemsToSend: PropTypes.func.isRequired,
    itemsToSend: PropTypes.arrayOf(PropTypes.shape().isRequired),
    sending: PropTypes.bool,
    title: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.shape(),
    }),
    pageSize: PropTypes.number,
    total: PropTypes.number,
    offset: PropTypes.number,
    lancamento: PropTypes.bool,
    promocao: PropTypes.bool,
  };

  static defaultProps = {
    match: null,
    title: null,
    itemsToSend: [],
    sending: false,
    pageSize: 100,
    total: 0,
    offset: 0,
    lancamento: false,
    promocao: false,
  };

  componentDidMount() {
    const { getOrderRequest, offset, lancamento, promocao } = this.props;

    this.sendSearch(offset, lancamento, promocao);

    getOrderRequest();
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: {
          term,
          industry,
          type,
          categoryGroup,
          category,
        },
      },
      offset,
    } = this.props;
    const {
      match: {
        params: {
          term: prevTerm,
          industry: prevIndustry,
          type: prevType,
          categoryGroup: prevCategoryGroup,
          category: prevCategory,
        },
      },
    } = prevProps;
    if (
      term !== prevTerm
      || industry !== prevIndustry
      || type !== prevType
      || categoryGroup !== prevCategoryGroup
      || category !== prevCategory
    ) {
      this.sendSearch(offset);
    }
  }

  sendSearch = (newOffset = 0, lancamento = false, promocao = false) => {
    const {
      productRequest,
      industryRequest,
      setSearchTerm,
      setSearchIndustry,
      setSearchProductType,
      setSearchCategoryGroup,
      setSearchCategory,
      match: {
        params: { term, industry, type, categoryGroup, category},
      },
      offset,
    } = this.props;

    if (term || industry || type || categoryGroup || category) {
      this.changePage(0);
      industryRequest();
      productRequest({
        term,
        industry,
        type,
        categoryGroup,
        category,
        offset: newOffset,
      });
      setSearchTerm(term, newOffset);
      setSearchIndustry(industry);
      setSearchProductType(type);
      setSearchCategoryGroup(categoryGroup);
      setSearchCategory(category);
    } else {
      productRequest({
        lancamento,
        promocao,
        offset: Number.isInteger(newOffset) ? newOffset : offset,
      });
    }
  };

  setQt = (product, qtd) => {
    const newProduct = { ...product, qtd };
    let { itemsToSend } = this.props;
    const { setItemsToSend } = this.props;

    itemsToSend = itemsToSend.filter(
      (item) => product.cd_mercadoria !== item.cd_mercadoria
    );

    const maxQtd = newProduct.infos.qt_fisica - newProduct.infos.qt_reservada;
    if (qtd > maxQtd) {
      newProduct.qtd = maxQtd;
    }
    if (product.bloqueio) {
      const availableQtd =
        product.bloqueio.qt_limita_venda -
        product.bloqueio.qt_pedido_dia -
        product.bloqueio.qt_vendida;
      if (qtd > availableQtd) {
        newProduct.qtd = availableQtd;
      }
    }

    if (newProduct.qtd > 0) {
      itemsToSend.push(newProduct);
    }
    setItemsToSend(itemsToSend);
  };

  getAddButtonText = () => {
    const { itemsToSend } = this.props;

    return !itemsToSend || (itemsToSend && itemsToSend.length === 0)
      ? "Nenhum item para adicionar"
      : `Adicionar ${itemsToSend.length} ite${
          itemsToSend.length > 1 ? "ns" : "m"
        } no pedido`;
  };

  sendItems = (event) => {
    event.preventDefault();

    const { sendItemsRequest, itemsToSend } = this.props;

    const arrPrepared = [];
    itemsToSend.forEach((item) => {
      arrPrepared.push({
        mercadoria: item.full_cd_mercadoria,
        qtd: parseInt(item.qtd, 10),
        placeholder: parseInt(item.placeholder, 10) || 0,
      });
    });

    sendItemsRequest(arrPrepared, true);
  };

  changePage = (data) => {
    const pageNumber = data.selected; // Inicia em 0
    const { pageSize, total } = this.props;
    const totalPages = Math.ceil(total / pageSize) - 1;
    if (pageNumber >= 0 || pageNumber < totalPages) {
      this.sendSearch(pageSize * pageNumber);
    }
  };

  render() {
    const { title, sending, itemsToSend, pageSize, total, promocao, lancamento } = this.props;
    const totalPages = Math.ceil(total / pageSize);

    setTitle(title || "Bem vindo!");

    return (
      <Container onSubmit={this.sendItems}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <h1>{title || "Produtos"}</h1>

          {!promocao && !lancamento && <ColorsLegend />}
        </Box>

        <ProductList
          onQtChange={this.setQt}
          itemsToSend={itemsToSend}
          rowHighlight={!promocao && !lancamento}
        />

        <PaginatorContainer>
          <ReactPaginate
            previousLabel="Anterior"
            nextLabel="Próximo"
            marginPagesDisplayed={1}
            breakLabel="..."
            pageCount={totalPages}
            onPageChange={this.changePage}
            pageRangeDisplayed={5}
            containerClassName="pagination"
          />
        </PaginatorContainer>

        <ButtonsContainer>
          <p>
            <BtnPrimary
              type="submit"
              disabled={
                !itemsToSend || (itemsToSend && itemsToSend.length === 0)
              }
            >
              {sending ? (
                <LoaderContainer>
                  <Loader
                    type="ThreeDots"
                    color="rgba(255, 255, 255, 0.5)"
                    height={15}
                    width={30}
                  />
                  <span>enviando...</span>
                </LoaderContainer>
              ) : (
                this.getAddButtonText()
              )}
            </BtnPrimary>
          </p>
        </ButtonsContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  sending: state.order.sending,
  itemsToSend: state.order.itemsToSend,
  total: state.products.total,
  pageSize: state.products.limit,
  offset: state.products.offset,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...ProductsActions,
      ...OrderActions,
      ...IndustriesActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Products);
