import React from 'react';

import { Switch, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import GATracker from './GATracker';

import Login from '../pages/Login';
import Main from '../pages/Main';
import Products from '../pages/Products';
import SearchResult from '../pages/SearchResult';
import Order from '../pages/Order';
import OrderImport from '../pages/OrderImport';
import NoMatch from '../pages/NoMatch';
import Unauthorized from '../pages/Unauthorized';
import Releases from '../pages/Releases';

const Routes = () => (
  <Switch>
    <Route exact path="/login/:token/:project/:coreIndustry" component={GATracker(Login)} />
    <Route exact path="/unauthorized" component={GATracker(Unauthorized)} />
    <PrivateRoute exact path="/" component={GATracker(Main)} />
    <PrivateRoute
      exact
      path="/produtos/buscar/:term/:industry/:type/:categoryGroup/:category"
      component={GATracker(SearchResult)}
    />
    <PrivateRoute exact path="/produtos" component={GATracker(Products)} />
    <PrivateRoute exact path="/pedido" component={GATracker(Order)} />
    <PrivateRoute exact path="/pedido/importar" component={GATracker(OrderImport)} />
    <PrivateRoute exact path="/lancamentos" component={GATracker(Releases)} />
    <Route component={GATracker(NoMatch)} />
  </Switch>
);

export default Routes;
