import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Products from '../Products';

const SearchResult = ({ match, industries, ...rest }) => {
  const title = 'Ofertas da Semana';

  return <Products {...rest} match={match} title={title} promocao />;
};

SearchResult.propTypes = {
  industries: PropTypes.arrayOf({
    cd_fornecedor: PropTypes.number.isRequired,
    ds_fornecedor: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  industries: state.industries.data,
});

export default connect(mapStateToProps)(SearchResult);
