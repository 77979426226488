import React from 'react';

import { Link } from 'react-router-dom';

import { Container, BtnNavbar } from './style';

const Navbar = () => (
  <Container>
    <BtnNavbar>
      <Link to="/produtos">
        Produtos
      </Link>
    </BtnNavbar>
    <BtnNavbar>
      <Link to="/pedido">
        Pedido
      </Link>
    </BtnNavbar>
    <BtnNavbar>
      <Link to="/pedido/importar">
        Importar Arquivo
      </Link>
    </BtnNavbar>
    <BtnNavbar highlight>
      <Link to="/lancamentos">
        Lançamentos
      </Link>
    </BtnNavbar>
  </Container>
);

export default Navbar;
