/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";

import ProductName from '../../../ProductName';
import ProductInfo from '../../../ProductInfo';

import {
  ItemLine,
  QtdColumn,
  VlrColumn,
  CodColumn,
  NoQtdColumn,
  PriceItem,
  PriceLabel,
  PriceValue,
  PriceColumn,
  TotalPriceItem,
  SubPriceItem,
  AddPriceItem,
  PriceItemSeparator,
  PriceSuggestionItem,
  WideVlrColumn,
} from "../../../../styles/common";

function ResultItem(props) {
  const { product, onQtChange, qtdOnOrder, value, rowHighlight } = props;

  const showQtdBox =
    product.infos.id_falta === "N" &&
    !(product.bloqueio && product.bloqueio.qt_disponivel <= 0);
  let qtDisponivel = null;
  let maxQtd = null;
  let readOnly = null;
  if (product.bloqueio) {
    qtDisponivel = product.bloqueio.qt_disponivel;
    maxQtd = ((qtDisponivel) ? qtDisponivel : 0) - qtdOnOrder;
    readOnly = maxQtd < 0;
  }

  return (
    <ItemLine
      bloqueio={product.bloqueio}
      lancamento={rowHighlight && product.infos.id_lancamento === 'S'}
      promocao={rowHighlight && product.infos.id_promocao_semanal === 'S'}
    >
      <CodColumn>{`${product.full_cd_mercadoria}`}</CodColumn>
      <td>
        <ProductInfo product={product} />
      </td>
      <td>
        <ProductName product={product} />
      </td>
      {showQtdBox ? (
        <QtdColumn
          estoqueBaixo={
            product.infos.qt_fisica - product.infos.qt_reservada <= 15
          }
        >
          <input
            onChange={(event) => onQtChange(
                {
                  ...product,
                  placeholder: qtdOnOrder,
                },
                event.target.value
              )
            }
            placeholder={qtdOnOrder}
            type="number"
            readOnly={readOnly}
            value={value}
            max={maxQtd}
            min={0}
          />
        </QtdColumn>
      ) : (
        <NoQtdColumn>---</NoQtdColumn>
      )}
      <VlrColumn>{`${product.infos.vl_total_sem_st_formated}`}</VlrColumn>
      <VlrColumn>{`${product.infos.vl_imposto_formated}`}</VlrColumn>
      {product.cd_fornecedor.toString() === "343" ? (
        <PriceColumn>
          <PriceItem>
            <PriceLabel>Preço de fabrica:</PriceLabel>
            <PriceValue>{product.infos.vl_preco_fabrica_formated}</PriceValue>
          </PriceItem>
          <SubPriceItem>
            <PriceLabel>Desconto(NF):</PriceLabel>
            <PriceValue>
              {product.infos.vl_desconto_nota_formated}
              <br />({product.infos.pc_desconto_nota}%)
            </PriceValue>
          </SubPriceItem>
          <hr />
          <PriceItem>
            <PriceLabel>Preço(NF):</PriceLabel>
            <PriceValue>{product.infos.vl_preco_liq_formated}</PriceValue>
          </PriceItem>
          {/* <SubPriceItem>
                <PriceLabel>Crédito&nbsp;boleto:</PriceLabel>
                <PriceValue>
                  {product.infos.vl_desconto_financeiro_formated}
                  <br />
                  ({product.infos.pc_desconto_financeiro}%)
                </PriceValue>
              </SubPriceItem> */}
          <PriceSuggestionItem>
            <PriceLabel>PMC:</PriceLabel>
            <PriceValue>
              {product.infos.vl_preco_maximo_consumidor_formated}
            </PriceValue>
          </PriceSuggestionItem>
          <hr />
          <TotalPriceItem>
            <PriceLabel>Preço sem ST:</PriceLabel>
            <PriceValue>{product.infos.vl_total_sem_st_formated}</PriceValue>
          </TotalPriceItem>
          <AddPriceItem>
            <PriceLabel>ST:</PriceLabel>
            <PriceValue>{product.infos.vl_imposto_formated}</PriceValue>
          </AddPriceItem>
          <PriceItemSeparator />
          <PriceItem>
            <PriceLabel>Preço final com ST:</PriceLabel>
            <PriceValue>{product.infos.vl_total_formated}</PriceValue>
          </PriceItem>
        </PriceColumn>
      ) : (
        <WideVlrColumn>{`${product.infos.vl_total_formated}`}</WideVlrColumn>
      )}
    </ItemLine>
  );
}

ResultItem.propTypes = {
  onQtChange: PropTypes.func.isRequired,
  qtdOnOrder: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  product: PropTypes.shape({
    full_cd_mercadoria: PropTypes.string.isRequired,
    cd_mercadoria: PropTypes.number.isRequired,
    cd_fornecedor: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    ds_apresentacao: PropTypes.string.isRequired,
    ds_mercadoria: PropTypes.string.isRequired,
    bloqueios: PropTypes.shape({
      cd_cliente: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cd_conexao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cd_mercadoria: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      dt_atualizacao: PropTypes.string,
      dt_final: PropTypes.string,
      dt_inicial: PropTypes.string,
      id_situacao: PropTypes.string,
      nm_usuario: PropTypes.string,
      qt_limita_venda: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      qt_pedido_dia: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      qt_vendida: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
    infos: PropTypes.shape({
      vl_preco_liq_formated: PropTypes.string.isRequired,
      vl_imposto_formated: PropTypes.string.isRequired,
      vl_total_formated: PropTypes.string.isRequired,
      vl_total_sem_st_formated: PropTypes.string.isRequired,
      vl_preco_fabrica_formated: PropTypes.string.isRequired,
      vl_desconto_financeiro_formated: PropTypes.string.isRequired,
      vl_desconto_nota_formated: PropTypes.string.isRequired,
      vl_preco_maximo_consumidor_formated: PropTypes.string.isRequired,
      qt_fisica: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      qt_reservada: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      pc_desconto_nota: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      pc_desconto_financeiro: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      vl_desconto_financeiro: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      vl_preco_maximo_consumidor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      id_falta: PropTypes.string.isRequired,
      id_lancamento: PropTypes.string.isRequired,
      id_promocao_semanal: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  rowHighlight: PropTypes.bool,
};

ResultItem.defaultProps = {
  rowHighlight: false,
};

export default ResultItem;
