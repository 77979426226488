import {
  Box,
  Button,
  Card,
  CardContent,
  Popover,
  makeStyles,
} from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import React, { useState } from 'react';

const useStyles = makeStyles({
  card: {
    '& .MuiCardContent-root': {
      padding: '0.25rem',
    },
  },
  button: {
    textTransform: 'none',
  },
});

function ColorsLegend() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'colors-legend-popover' : undefined;

  return (
    <>
      <Button
        onClick={handleClick}
        type="button"
        variant="text"
        size="small"
        className={classes.button}
      >
        Legenda das cores <ArrowIcon color="primary" />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card className={classes.card}>
          <CardContent>
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center" mb={1}>
                <Box width={20} height={20} bgcolor="#a55da5" />
                <Box ml={1}>Produto em Lançamento</Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box width={20} height={20} bgcolor="#99ccff" />
                <Box ml={1}>Produto com Desconto</Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}

export default ColorsLegend;
