import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { ProductsTable } from '../../styles/common';

import NoProductResult from './components/NoProductResult';
import LoadingResults from '../LoadingResults';
import ResultItem from './components/ResultItem';

const ProductList = ({
  loading, products, onQtChange, itemsOnOrder, itemsToSend, rowHighlight,
}) => (
  <ProductsTable>
    <thead>
      <tr>
        <th>Cód.</th>
        <th>Inf.</th>
        <th>Mercadoria</th>
        <th>Qtd</th>
        <th>Preço líq.</th>
        <th>Imposto</th>
        <th>Preço final com ST</th>
      </tr>
    </thead>
    <tbody>
      {loading && <LoadingResults />}
      {!loading && products.length === 0 ? (
        <NoProductResult colSpan="6" />
      ) : (
        products.map((product) => {
          let qtdOnOrder = 0;
          let qtyAsked = '';
          for (let i = 0; i < itemsOnOrder.length; i += 1) {
            if (product.cd_mercadoria === itemsOnOrder[i].cd_mercadoria) {
              qtdOnOrder = itemsOnOrder[i].qt_pedida;
              break;
            }
          }

          for (let i = 0; i < itemsToSend.length; i += 1) {
            if (product.cd_mercadoria === itemsToSend[i].cd_mercadoria) {
              qtyAsked = itemsToSend[i].qtd;
              break;
            }
          }
          return (
            <ResultItem
              key={product.cd_mercadoria}
              qtdOnOrder={qtdOnOrder}
              onQtChange={onQtChange}
              product={product}
              value={qtyAsked}
              rowHighlight={rowHighlight}
            />
          );
        })
      )}
    </tbody>
  </ProductsTable>
);

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.string,
      PropTypes.number,
      PropTypes.string,
      PropTypes.string,
      PropTypes.string,
      PropTypes.shape(),
      PropTypes.number,
      PropTypes.any,
    ]),
  ).isRequired,
  itemsOnOrder: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  itemsToSend: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  onQtChange: PropTypes.func.isRequired,
  rowHighlight: PropTypes.bool,
};

ProductList.defaultProps = {
  rowHighlight: false,
};

const mapStateToProps = state => ({
  products: state.products.data,
  loading: state.products.loading,
  itemsOnOrder: state.order.data.items,
});

export default connect(mapStateToProps)(ProductList);
