import styled from 'styled-components';

export const Container = styled.ul`
  list-style: none;
  display: flex;
  height: 30px;
  background: #8CC7DB;
`;

export const BtnNavbar = styled.li`
  height: 100%;
  line-height: 30px;
  a {
    display: block;
    color: ${(props) => (props.highlight ? "#f00" : "#324852")};
    padding: 0 25px;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      background-color: #81AFC6;
    }
  }
`;
