/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as ParcelamentoActions } from "../../store/ducks/parcelamento";

import {
  Container,
  ValorInvalido,
  ParcelamentoTableHead,
  ParcelamentoTableBody,
} from "./style";
import DefaultParcelamento from "./DefaultParcelamento";
import FixedParcelamento from "./FixedParcelamento";
import Solar from "./Solar";

import { numberRemoveFormat } from "../../util/numberFormat";
import { getClientData } from "../../services/auth";
import { CondicoesDeParcelamentoFixo } from "../../services/condicoesParcelamento";

class Parcelamento extends Component {
  state = {
    totalNumber: 0,
    cdProjeto: "",
  };

  static propTypes = {
    total: PropTypes.string.isRequired,
    isCampanhaSolar: PropTypes.bool.isRequired,
    getParcelamentoRequest: PropTypes.func.isRequired,
    setParcelamentoGenerico: PropTypes.func.isRequired,
    setParcelamentoSolar: PropTypes.func.isRequired,
    parcela: PropTypes.string.isRequired,
    condicoesParcelamento: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          parcela: PropTypes.number,
          vencimento: PropTypes.string,
          valor: PropTypes.string,
        })
      ),
      PropTypes.string,
    ]).isRequired,
    parcelamentoGenericos: PropTypes.bool.isRequired,
    qtdDias: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    minimum: PropTypes.number.isRequired,
  };

  componentDidMount() {
    const { getParcelamentoRequest, total } = this.props;

    const newTotal = numberRemoveFormat(total);
    this.updateTotalNumber(newTotal);

    this.setState({ cdProjeto: getClientData().cd_logged_project });

    getParcelamentoRequest();
  }

  componentDidUpdate(prevProps) {
    const { getParcelamentoRequest, minimum, total } = this.props;
    const { total: prevTotal } = prevProps;

    if (prevTotal !== total) {
      const newTotal = numberRemoveFormat(total);
      this.updateTotalNumber(newTotal);

      if (newTotal >= minimum) {
        getParcelamentoRequest();
      }
    }
  }

  updateTotalNumber = (totalNumber) => {
    this.setState({
      totalNumber,
    });
  };

  changeParcelamentoGenericos = (value) => {
    const { setParcelamentoGenerico } = this.props;

    if (value === "") {
      setParcelamentoGenerico("", "");
    } else if (value.toString() === "30" || value.toString() === "60") {
      setParcelamentoGenerico(value, "");
    } else {
      setParcelamentoGenerico("", value);
    }
  };

  changeParcelamentoEspecial = (value) => {
    const { setParcelamentoEspecial } = this.props;

    if (value === "") {
      setParcelamentoEspecial("", "");
    } else if (value.toString() === "30" || value.toString() === "60") {
      setParcelamentoEspecial(value, "");
    } else {
      setParcelamentoEspecial("", value);
    }
  };

  changeParcelamentoSolar = (value) => {
    const { setParcelamentoSolar } = this.props;

    if (value === "") {
      setParcelamentoSolar("", "");
    } else if (
      value.toString() === "30" ||
      value.toString() === "60" ||
      value.toString() === "90"
    ) {
      setParcelamentoSolar(value, "");
    } else {
      setParcelamentoSolar("", value);
    }
  };

  render() {
    const { totalNumber, cdProjeto } = this.state;
    const {
      isCampanhaSolar,
      condicoesParcelamento,
      parcelamentoGenericos,
      qtdDias,
      minimum,
    } = this.props;

    if (minimum === 0) {
      return <></>;
    }
    const clientData = getClientData();
    // Ativa condição especial de parcelamento
    let parcelamentoEspecial = false;
    if (
      cdProjeto.toString() === "1" &&
      totalNumber > 1000 &&
      new Date().valueOf() >= 1732762800000 && // 28/11/2024
      new Date().valueOf() < 1736823600000 // 13/01/2025 (00h of 14/01/2025)
    ) {
      parcelamentoEspecial = CondicoesDeParcelamentoFixo.especial["30/60/90"];
    } else if (
      cdProjeto.toString() === "3" &&
      totalNumber > 10000 &&
      new Date().valueOf() >= 1734490800000 && // 18/12/2024
      new Date().valueOf() < 1734577200000 // 18/12/2024 (00h of 19/12/2024)
    ) {
      parcelamentoEspecial = CondicoesDeParcelamentoFixo.especial["30/60/90"];
    }

    return (
      <Container>
        {totalNumber < minimum ? (
          <ValorInvalido>
            Para poder parcelar este pedido o valor total do pedido precisa ser
            maior ou igual a R$ {minimum},00.
          </ValorInvalido>
        ) : (
          <Container>
            {isCampanhaSolar ? (
              <Solar
                qtdDias={qtdDias}
                changeParcelamentoSolar={this.changeParcelamentoSolar}
                condicoesSolar={CondicoesDeParcelamentoFixo.solar}
                cdProjeto={cdProjeto}
                condicoes={CondicoesDeParcelamentoFixo.solar}
              />
            ) : parcelamentoGenericos ? (
              <FixedParcelamento
                qtdDias={qtdDias}
                changeParcelamento={this.changeParcelamentoGenericos}
                condicoes={CondicoesDeParcelamentoFixo.genericos}
              />
            ) : parcelamentoEspecial ? (
              <FixedParcelamento
                qtdDias={qtdDias}
                changeParcelamento={this.changeParcelamentoEspecial}
                condicoes={parcelamentoEspecial}
              />
            ) : (
              <DefaultParcelamento />
            )}
            <table>
              <ParcelamentoTableHead>
                <tr>
                  <th>Parcela</th>
                  <th>Valor</th>
                  <th>Vencimento</th>
                </tr>
              </ParcelamentoTableHead>
              <ParcelamentoTableBody>
                {condicoesParcelamento.length > 0 ? (
                  condicoesParcelamento.map((value) => (
                    <tr key={value.parcela}>
                      <td>{value.parcela}</td>
                      <td>R$ {value.valor}</td>
                      <td>{value.vencimento}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">Selecione as condições de parcelamento</td>
                  </tr>
                )}
                <tr>
                  <td colSpan="3">
                    Obs.: Podem ser adicionadas despesas administrativas
                  </td>
                </tr>
              </ParcelamentoTableBody>
            </table>
          </Container>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ParcelamentoActions, dispatch);

const mapStateToProps = (state) => ({
  parcelas: state.parcelamento.parcelas,
  parcela: state.parcelamento.parcela,
  intervalos: state.parcelamento.intervalos,
  intervalo: state.parcelamento.intervalo,
  prazoMedio: state.parcelamento.prazoMedio,
  prazo: state.parcelamento.prazo,
  condicoesParcelamento: state.parcelamento.condicoesParcelamento,
  parcelamentoGenericos: state.parcelamento.parcelamentoGenericos,
  qtdDias: state.parcelamento.qtdDias,
  minimum: state.parcelamento.minimum,
});

export default connect(mapStateToProps, mapDispatchToProps)(Parcelamento);
